import React from "react";

export default function Footer() {
  return (
    <>
      <footer className="footer">
        <div className="footer-content">
          <div className="footer-content-c">
            <div className="footer-content-c-item">
              <img
                src="olimpo-small-logo.png"
                alt="Olimpo Restobar"
                className="logo-footer"
              ></img>
              <img
                src="banner.png"
                alt="niubiz payment"
                className="niubiz"
              ></img>
            </div>
            <div className="footer-content-c-item">
              <div className="footer-socialmedia">
                <a href="https://www.instagram.com/el.olimpo.restobar/">
                  <img src="icons/instagram.png" alt="instagram" />
                </a>
                <a href="https://www.facebook.com/elolimporestobar/">
                  <img src="icons/facebook.png" alt="facebook" />
                </a>
                <a href="https://www.tiktok.com/@el.olimpo.restobar/">
                  <img
                    className="tiktokPn"
                    src="icons/tik-tok.png"
                    alt="tiktok"
                  />
                </a>
              </div>

              <p className="p-footer">
                contacto@olimporestobar.com
                <br />
                51 903343236
              </p>
            </div>
            <div className="footer-content-c-item">
              <h3>HORARIO DE ATENCION</h3>

              <p className="p-footer">
                Miraflores: Dom - Jue: 4pm - 1am
                <br />
                Miraflores: Vie - Sab: 5pm - 3am
                <br />
                San Isidro: Dom - Jue: 3pm - 11pm
                <br />
                San Isidro: Vie - Sab: 5pm - 3am
              </p>
            </div>
          </div>
        </div>
      </footer>

      <style jsx='true'>{`

        p {
          color: #fff;
        }

        .footer {
          position: relative;
          background: #000;
        }
        .footer-content {
          padding: 30px 0;
        }

        .footer-content-c {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          max-width: 1024px;
          margin: 0 auto;
        }

        .footer-content-c-item {
          display: flex;
          align-items: center;
          flex-direction: column;
        }
        .niubiz {
          width: 220px;
        }

        .logo-footer {
          height: 100px;
        }

        .footer-content-c-item h3 {
          font-family: Shesmile;
          color: #fff;
          font-size: calc(20px + 4 * (100vw - 650px) / 950);
        }

        .p-footer {
          line-height: 1.875;
          font-family: AbelReg;
        }

        .footer-socialmedia {
          display: flex;
          width: 100%;
          padding: 1rem 0rem 0;
          position: relative;
          z-index: 4;
        }

        .footer-socialmedia a {
          margin: 1rem;
        }

        @media screen and (max-width: 480px) {
          .footer-content-c {
            align-items: center;
            flex-direction: column;
          }
          .footer-content {
            padding: 0;
          }
        }
      `}</style>
    </>
  );
}
