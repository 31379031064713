import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { onAuthStateChanged } from "../config/firebase";

export default function Topbar() {
  const [active, setActive] = useState(true);

  const [user, setUser] = useState(null);

  useEffect(() => {
    onAuthStateChanged(setUser);
  }, []);

  const handleClick = (event) => {
    setActive(!active);
  };

  return (
    <>
      <div
        className={
          active
            ? "wrap-menu-bg wrap-menu-bg-active "
            : "wrap-menu-bg wrap-menu-bg-inactive"
        }
      >
        <div className="menu-bg">
          <div className="black--overlay"></div>
          <div className="close-btn">
            <img src="close.png" alt="close" onClick={handleClick} />
          </div>
        </div>
        <nav className="nav-bg">
          <div className="nav-link">
            <ul>
              <li className="menu-item-li">
                <a className="menu-item" href="/">
                  Inicio
                </a>
              </li>
              <li className="menu-item-li">
                <Link className="menu-item" to="/Menu">
                  Carta
                </Link>
              </li>

              <li className="menu-item-li">
                <a href="/#giftcards" className="menu-item">
                  Gifcards
                </a>
              </li>

              <li className="menu-item-li">
                <a className="menu-item" href="/#book">
                  Reservas
                </a>
              </li>

              <li className="menu-item-li">
                <a className="menu-item" href="/#venues">
                  Franquicias
                </a>
              </li>
            </ul>
          </div>
          <div className="nav-socialmedia">
            <a href="https://www.instagram.com/el.olimpo.restobar/">
              <img src="icons/instagram.png" alt="instagram" />
            </a>
            <a href="https://www.facebook.com/elolimporestobar/">
              <img src="icons/facebook.png" alt="instagram" />
            </a>
            <a href="https://www.tiktok.com/@el.olimpo.restobar/">
              <img
                className="tiktokPn"
                src="icons/tik-tok.png"
                alt="instagram"
              />
            </a>
          </div>
        </nav>
      </div>
      <div className="hero-top-c">
        <div
          onClick={handleClick}
          className={
            active
              ? "hero-top-div wrap-menu-bg-inactive"
              : "hero-top-div wrap-menu-bg-active mediaquerySS"
          }
        >
          <img src="menu.png" alt="menu" className="menu-c" />
        </div>
        <div className="logo-c hero-top-div">
          <a href="/">
            <img src="logo-magtimus-small.png" alt="Logo Olimpo Restobar"></img>
          </a>
        </div>
        <div className="hero-top-div">
          <Link
            className="book-button-js"
            data-fancybox=""
            data-src="#hidden-content-footer"
            data-form=""
            to="/Login"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="148.496"
              height="149.047"
              viewBox="0 0 148.496 149.047"
              className=""
            >
              <path
                id="guitar_plectrum"
                d="M225.127,86.478a29.417,29.417,0,0,1-.988,7.105,52.415,52.415,0,0,1-3.557,9.354c-.483.973-.878,1.984-1.372,2.948-1.405,2.787-2.777,5.594-4.292,8.343-1.712,3.109-3.458,6.2-5.357,9.231-1.954,3.118-4.04,6.179-6.169,9.222a100.329,100.329,0,0,1-7.344,9.269c-1.855,2.06-3.721,4.12-5.7,6.094-1.471,1.474-3.106,2.835-4.709,4.214-1.91,1.625-3.864,3.213-5.8,4.819a10.881,10.881,0,0,1-6.026,2.343,21.888,21.888,0,0,1-6.432-.151,10.414,10.414,0,0,1-5.06-2.277c-1.647-1.465-3.513-2.731-5.159-4.2-2.371-2.1-4.731-4.214-6.894-6.463-2.514-2.608-4.94-5.3-7.179-8.1-2.525-3.146-4.841-6.425-7.179-9.685-1.2-1.663-2.305-3.392-3.348-5.131-1.361-2.239-2.634-4.516-3.919-6.794q-1.63-2.905-3.183-5.839c-.714-1.361-1.372-2.74-2.02-4.12-1.043-2.192-2.053-4.4-3.074-6.6a31.769,31.769,0,0,1-2.6-8.419,52.836,52.836,0,0,1-.615-5.726,35.628,35.628,0,0,1,.768-9.014,27.158,27.158,0,0,1,3.644-9,29.568,29.568,0,0,1,6.411-7.181,39.146,39.146,0,0,1,6.345-4.242,49.858,49.858,0,0,1,8.079-3.543,55.465,55.465,0,0,1,8.386-2.145,39.489,39.489,0,0,1,6.52-.6c2.064.009,4.116-.142,6.18-.208.417-.019.845-.009,1.262-.009,3.688-.019,7.377-.085,11.054-.038s7.344.123,11.01.312a44.552,44.552,0,0,1,9.078,1.493c.867.227,1.756.444,2.6.728a56.906,56.906,0,0,1,8.441,3.5,40.864,40.864,0,0,1,9.254,6.444,25.658,25.658,0,0,1,4.962,6.3,36.017,36.017,0,0,1,2.173,4.658,24.742,24.742,0,0,1,1.493,6.567c.187,2.183.307,4.365.45,6.538A.362.362,0,0,0,225.127,86.478Z"
                transform="translate(-21.632 -101.792) rotate(30)"
                fill="#FF0000"
              ></path>
            </svg>

            {user === null ? (
              <p>
                Iniciar
                <br />
                Sesion
              </p>
            ) : (
              <p className="p-username">{user.name.substring(0, 6)}!</p>
            )}
          </Link>
        </div>
      </div>
      <style jsx="true">
        {`
          li,
          ul {
            list-style-type: none;
          }

          .wrap-menu-bg {
            width: 275px;
            height: 810px;
            position: fixed;
            z-index: 9999;
            -webkit-transition: all 0.5s 0s ease;
            -moz-transition: all 0.5s 0s ease;
            -o-transition: all 0.5s 0s ease;
            transition: all 0.5s 0s ease;
          }

          .wrap-menu-bg-inactive {
            visibility: hidden;
            opacity: 0;
          }

          .wrap-menu-bg-active {
            visibility: inherit;
            opacity: 1;
            -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1)
              both;
            animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
          }
          .close-btn {
            position: absolute;
            padding: 2rem;
            top: 4%;
            z-index: 9999;
            cursor: pointer;
          }
          .nav-bg {
            margin-top: 4rem;
          }

          .black--overlay {
            border-bottom-right-radius: 500px;
            background-color: rgba(0, 0, 0, 0.7);
            position: absolute;
            width: 100%;
            height: 100%;
          }

          .menu-item {
            color: #fff;
            text-decoration: none;
            font-size: 2rem;
            font-family: AlvaroCondensed;
          }

          .menu-item:hover {
            color: #fe0000;
          }
          .menu-item-li {
            margin-bottom: 1.5px;
            margin-top: 1rem;
          }

          .nav-link ul {
            position: relative;
            z-index: 4;
            padding-top: 5rem;
          }

          .nav-socialmedia {
            display: flex;
            width: 100%;
            padding: 2rem;
            position: relative;
            z-index: 4;
          }

          .nav-socialmedia a {
            margin: 1rem;
          }
          .tiktokPn {
            filter: brightness(0%) invert(100%);
            -webkit-filter: brightness(0%) invert(100%);
            -moz-filter: brightness(0%) invert(100%);
            height: 20px;
          }

          .hero-top-c {
            display: flex;
            justify-content: space-between;
            padding: 0.5rem 2rem;
            align-items: center;
          }

          .hero-top-div {
            position: relative;
            -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1)
              both;
            animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
          }

          @-webkit-keyframes fade-in {
            0% {
              opacity: 0;
            }
            100% {
              opacity: 1;
            }
          }
          @keyframes fade-in {
            0% {
              opacity: 0;
            }
            100% {
              opacity: 1;
            }
          }

          .menu-c {
            position: fixed;
            top: 60px;
            cursor: pointer;
            z-index: 999;
          }

          .logo-c {
            height: 150px;
            margin-left: 147px;
          }
          .logo-c img {
            height: 100%;
            width: 100%;
            object-fit: contain;
          }

          .book-button-js p {
            position: absolute;
            top: 15%;
            left: 36%;
            font-weight: bold;
            color: #fff;
            font-family: AlvaroCondensed;
            font-size: 1.5rem;
          }
          .book-button-js .p-username {
            font-size: 1.8rem;
          }

          .book-button-js svg {
            -webkit-transition: transform 0.5s 0s ease;
            -moz-transition: transform 0.5s 0s ease;
            -o-transition: transform 0.5s 0s ease;
            transition: transform 0.5s 0s ease;
          }

          .book-button-js:hover svg {
            transform: scale(1.1);
          }

          @media screen and (max-width: 480px) {
            .hero-top-c {
              padding: 0.5rem 1rem;
            }

            .mediaquerySS {
              width: 36px;
              height: 36px;
            }

            .menu-c {
              top: 52px;
            }
            .logo-c {
              height: 120px;
              margin-left: 50%;
            }
            .book-button-js {
              display: none;
            }

            .wrap-menu-bg {
              width: 100%;
            }

            .nav-link ul {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              padding-left: 0;
            }

            .nav-socialmedia {
              padding: 2rem 0;
              justify-content: center;
            }

            .black--overlay {
              border-bottom-right-radius: 0px !important;
            }
          }
        `}
      </style>
    </>
  );
}
