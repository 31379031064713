import React from "react";

export default function Loader() {
  return (
    <>
      <div className="loader-container">
        <div className="loader"></div>
      </div>
      <style jsx="true">{`
        .loader-container {
          height: 100vh;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #000;
        }
        .loader {
          border: 16px solid #f3f3f3; /* Light grey */
          border-top: 16px solid #ff0000; /* Blue */
          border-radius: 50%;
          width: 120px;
          height: 120px;
          animation: spin 2s linear infinite;
        }

        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      `}</style>
    </>
  );
}
