import React, { useState, useEffect} from 'react'
import axios from 'axios';

import { onAuthStateChanged  } from './config/firebase';
import { useParams } from 'react-router-dom';

export default function Checkout() {

    const [user, setUser] = useState(null);

      useEffect(() => {
        onAuthStateChanged(setUser)
    }, [])

    
    const [active, setActive] = useState(false);
    const [amount, setAmount] = useState(50);
    const [reciever, setReciever] = useState('');
    const [recieverEmail, setRecieverEmail] = useState('');
    const [message, setMessage] = useState('');
    const [sender, setSender] = useState('');
    const [senderEmail, setSenderEmail] = useState('prueba@gmail.com');
    const [clientID, setClientID] = useState('74629686');
    const [isReg, setIsReg] = useState("Registrado");
    const [cfreq, setCfreq] = useState(1);
    const [currency, setCurrency] = useState('PEN');
    const [days, setDays] = useState(() => {
      if(user != null) {
        const f = new Date(user.metadata.createdAt);
        const d = new Date() - f;
        return d;
      } else {
        return 0;
      }
    });
    const generateOrderNumber = () => {
      return Math.floor(Math.random() * 1000000000);
    };
    const [orderID, setOrderId] = useState(generateOrderNumber());

    // aW50ZWdyYWNpb25lc0BuaXViaXouY29tLnBlOl83ejNAOGZG'
    // const [active2, setActive2 ] = useState(false);  
    // const randomId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    const randomString = () => {
      let text = "";
      const possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  
      for (let i = 0; i < 8; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));
  
      return text;
    }
    function createOrder () {
      window.open(`http://localhost:3000/createOrder?amount=${amount}&senderEmail=${senderEmail}&clientID=${clientID}&isReg=${isReg}&cfreq=${cfreq}&days=${days}&sender=${sender}&reciever=${reciever}&recieverEmail=${recieverEmail}&message=${message}&orderID=${orderID}&currency=${currency}`)
    }
  
    // function createOrder() {
    //     const options = {
    //       method: "POST",
    //       url: `https://apisandbox.vnforappstest.com/api.ecommerce/v2/ecommerce/token/session/${merchandid}`,
    //       headers: {
    //         accept: "application/json",
    //         "content-type": "application/json",
    //         Authorization: token,
    //       },
    //       data: {
    //         antifraud: {
    //           merchantDefineData: {
    //             MDD4: senderEmail,
    //             MDD21: 1,
    //             MDD32: clientID,
    //             MDD75: "Registrado",
    //             MDD77: 10,
    //           },
    //         },
    //         channel: "web",
    //         amount: `${amount}.00`,
    //       },
    //     };
  
    //     axios
    //       .request(options)
    //       .then(function (response) {
    //         openForm(response.data.sessionKey);
    //       })
    //       .catch(function (error) {
    //         console.error(error);
    //       });
    // }


    // useEffect(() => {
    //   axios.get('http://localhost:3001/api/backend/getToken').then((response) => {
    //     setToken(response.data.token);
    //     setMerchandid(response.data.merchandid);
    // }) 

    // }, [])

    let { id } = useParams();

    const handleClick = () => {
      setActive(!active);
    }

    const handleClick2 = () => {
      setActive(!active);
    }

    // const notRegister = () => {
    //   setIsReg("Invitado");
    // }
  return (
    <>
    
        <div className='home-background'>
                <section className='sectionGif'>
               
                    <div className='checkout-form'>
                        <div className='checkout-title'> 
                          <a href='/' className='checkout-return'>
                            <img src='icons/chevron-left.png' alt='return' />
                            Volver
                          </a>
                          <h1>Crea tu GiftCard </h1>
                        </div>                           
                                            
                        {/* <form method='post' action='prod/pagoIncrustado' className='form-checkout'> */}
                        <form className='form-checkout'>  
                            <div className='form-group'>
                                <div className='gift-card-img'>
                                  <img src={`giftcards/${id}.png`} alt='GiftCard'/>
                                </div>
                                    <input id='id' name='id' type='hidden' value={id} />

                                    <input id='email' name='email' type='hidden' value={senderEmail} />

                                    <input id='cfreq' name='cfreq' type='hidden' value={cfreq} />

                                    <input id='clientID' name='clientID' type='hidden' value={clientID} />

                                    <input id='isReg' name='isReg' type='hidden' value={isReg} />

                                    <input id='days' name='days' type='hidden' value={days} />

                                <div className='form-group-item'>
                                    <label htmlFor='amount'>Monto de la GiftCard</label>
                                    <input className='form-group-item-individual' type='number' id='amount' name='amount' required onChange={(e) => setAmount(e.target.value)} placeholder='Monto'/>
                                </div>

                                <div className='form-group-item'>
                                    <label htmlFor='currency'>Moneda</label>
                                    <select className='form-group-item-individual' id='currency' name='currency' value={currency} required onChange={(e) => setCurrency(e.target.value)}>
                                        <option value='PEN'>Soles</option>
                                        <option value='USD'>Dolares</option>
                                    </select>
                                </div>

                                <div className='form-group-item'>
                                    <label htmlFor='recipient'>Receptor</label>
                                    <input className='form-group-item-individual' type='text' id='recipientName' required name='recipientName' placeholder='Nombre del receptor' onChange={(e) => setReciever(e.target.value)}  />
                                    <input className='form-group-item-individual' type='text' id='recipientEmail' required name='recipientEmail' placeholder='Telefono del receptor' onChange={(e) => setRecieverEmail(e.target.value)}/>
                                </div>
                                <div className='form-group-item'>
                                    <label htmlFor='senderName'>Remitente</label>
                                    <input className='form-group-item-individual' type='text' id='senderName' name='senderName' required placeholder='Nombre del remitente' onChange={(e) => setSender(e.target.value)}/>
                                    <input className='form-group-item-individual' type='email' id='senderEmail' name='senderEmail' required placeholder='Email del remitente' onChange={(e) => setSenderEmail(e.target.value)}/>
                                </div>

                                <div className='form-group-item'>
                                    <label htmlFor='senderName'>Documento de identidad</label>
                                   
                                    <input className='form-group-item-individual' type='text' id='clientID' name='clientID' required placeholder='Documento de identidad' onChange={(e) => setClientID(e.target.value)}/>
                                </div>
                                <div className='form-group-item'>
                                    <label htmlFor='message'>Mensaje <span className='form-optional'>(opcional)</span></label>
                                    <textarea id='message' name='message' placeholder='Si deseas enviale un mensaje al receptor' onChange={(e) => setMessage(e.target.value)}></textarea>
                                </div>

                                <div className='form-group-item'>
                                    <div className='btn-comprar' onClick={handleClick2}>Checkout</div>
                                </div>
                               
                            </div>


                            <div className={active ? 'form-group form-group-conf  active-f' : 'form-group form-group-conf  unactive-f'}>
                                <div className='checkount-close' onClick={handleClick}>
                                    <img src='close.png' alt='close' className='close'/>
                                </div>

                                  { user === null ? 
                                  <>
                                  <div className='form-group-item'>
                               
                                    <button className='btn-comprar' onClick={createOrder}>
                                            Continuar como invitado
                                    </button>
                                  </div>
                                  {/* <div className='form-group-item'>
                                      <a href='/login' className='btn-comprar'>Iniciar sesion</a>
                                  </div> */}
                                </>
                                :
                                  <div className='form-group-item'>                                                                  
                                      <button className='btn-comprar' onClick={createOrder}>
                                              Continuar como {user.name}      
                                      </button>
                                  </div>
                                }
                            </div>
                        </form>       
                    </div>
                </section>       
        </div>
    <style jsx='true'> {`
    @font-face {
    font-family: AlvaroCondensed;
    src: url("fonts/Alvaro-Condensed.otf") format("opentype");
    }
    
    @font-face {
      font-family: SheSmile;
      src: url("fonts/SheSmiles.otf") format("opentype");
    }
    
    @font-face {
      font-family: AbelReg;
      src: url("fonts/Abel-Regular.ttf") format("opentype");
    }
    
    @font-face {
    font-family: Pacifico;
    src: url("fonts/Pacifico-Regular.ttf") format("opentype");
    }
  
    .home-background {
            background-image: url('bg-f.jpg');  
            position: relative;
            display: block;
            overflow-x: hidden;
            background-size: contain;
            background-attachment: fixed;
        }

      .home-background::before {
        content: "";
        position: absolute;
        background-color: rgba(0,0,0,.85);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      section {
        position: relative;
        height:100vh;
       
      }

      .sectionGif{
        
      display: flex;
    
      align-items: center;
      justify-content: center;
      margin-top: 7%;
      }

      .checkout-form {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: repeat(4, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px;
      }

      .checkout-title {
        grid-area: 2 / 1 / 2 / 2;
        align-self: self-end;
        justify-self: self-end; 
      }

      .checkout-title h1 {
        font-family: Shesmile, sans-serif;  
        font-size: calc(38px + 4*(100vw - 650px)/950);
        color: #fff;
        letter-spacing: 1.2px;
      }

      .checkout-return {
        font-family: AbelReg, sans-serif;
        color: rgba(255,255,255,.5);
        font-size: calc(15px + 4*(100vw - 650px)/950);
        text-decoration: none;
        display:flex;
      }

      .form-checkout { 
        height: 85vh;
        overflow-x: hidden;
    }
      

      .gift-card-img {
        height: 180px;
        width: 300px;
        margin-bottom: 15px;
       
      }

      .gift-card-img img {
        height: 100%;
        width: 100%;
        object-fit: cover;  
        border-radius: 20px;
    }

    form {
      
      grid-area: 1 / 3 / 5 / 6;
      border-left: 1px solid rgba(255,255,255,.2);
      position: relative;
    }

      .form-group {
        display: flex;
        flex-direction: column;
        align-items: center;   
        transition: all .5s ease;
      }

      .form-group h4 {
        color: #fff;
      }

      .form-group-item:last-child {
        margin-top: 2rem;
      }

      .form-group-conf {
        
        position: absolute;
        background-color: rgba(255,255,255,.99);
        border: 2px solid #000;
    
        width: 100%;
        padding: 2rem 0;
        bottom: 3%;
      
        transition: all .5s ease;
      }

      .active-f {
        visibility: visible;
        opacity: 1; 
        display: flex;
        
      }

      .unactive-f {
        visibility: hidden;
        opacity: 0;
        display: none;
      }

      .arrow-up {
        transform: rotate(180deg);
      }

      .checkount-close {
        position: absolute;
        right: 0;
        top: 0;
        margin: 1rem;
        cursor: pointer;
      }

      .btn-comprar {          
            background-color: #ff0000;
            color: #fff;
            padding:.5rem 1rem;
            text-decoration: none;
            cursor: pointer;
            font-family: AbelReg, sans-serif;
            transition: opacity .5s ease;
            border: 2px solid #000;
            font-size: .9rem;
         }

         .disable {

            opacity: 0.6;
            cursor: not-allowed;
         }

         .btn-comprar:hover {
            opacity: 0.6;
         }
      
        .form-group-item {
            margin-top: 1rem;
            
            display:flex;
            gap: 1rem;  
            
            flex-direction: column;
        }

        .form-group-item label {
          font-family: AbelReg, sans-serif;
          font-weight: 600;
          color:#fff;
          font-size: 1.2rem;
        }

        .form-group-item-individual {
          border-radius: 8px;
          border: 1px solid rgba(255,255,255,.6);
          font-family: AbelReg, sans-serif;
          height: 2rem;
          padding-left: 5px;
          width: 300px;
         
        }

.checkbox-container {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.label-000 {
  color: #000!important;
}
.underline-text {
  text-decoration: underline;
}

        .form-group-item textarea {
          border-radius: 8px;
          border: 1px solid rgba(255,255,255,.6);
          height: 3rem;
          width: 300px;
          padding-left: 5px;
          font-family: AbelReg, sans-serif;
        }

        .form-optional{
          color: rgba(255,255,255,.4);
        }



        .slide-in-top {
	-webkit-animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


        @-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
    opacity: 0;
  }
  80% {
    opacity: 0.2
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
    opacity: 0;
  }
  80% {
    opacity: 0.2
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}



@media screen and (max-width: 485px) { 

  .home-background::before {
    height: 105%;
  }

  .sectionGif {
    margin-bottom: 40px;
  }
  .checkout-form {
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    grid-template-rows: repeat(4, 1fr);
  }

  .checkout-title {
    align-self: flex-end;
    justify-self: center;
    grid-area: 1 / 1 / 2 / 3;
  }
  .checkout-title h1 {
    margin-top: 0;
  }
  .form-checkout {
    grid-area: 2 / 1 / 5 / 3;
  }

  .form-group-conf {
    bottom: 0;
  }

  .form-group-item:last-child {
    margin-bottom: 1rem;
  }
}   
    `}
    </style>
    </>
  )
}
