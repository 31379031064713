import React, { useState, useEffect } from "react";
import { loginWithGoogle, onAuthStateChanged } from "./config/firebase";

export default function Login() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    onAuthStateChanged(setUser);
  }, [user]);

  const handleClick = () => {
    loginWithGoogle()
      .then((user) => {
        setUser(user);
        console.log(user);
      })
      .catch((error) => console.log(error));
  };
  return (
    <>
      <div className="home-background">
        <div className="login-with">
          {user === null ? (
            <button onClick={handleClick} className="google-login">
              Inicar sesion con Google
            </button>
          ) : (
            <div>
              <h2 className="white"> Bienvenido {user.name}</h2>
            </div>
          )}
        </div>
      </div>

      <style jsx='true'>
        {`
          @font-face {
            font-family: AlvaroCondensed;
            src: url("fonts/Alvaro-Condensed.otf") format("opentype");
          }

          @font-face {
            font-family: SheSmile;
            src: url("fonts/SheSmiles.otf") format("opentype");
          }

          @font-face {
            font-family: AbelReg;
            src: url("fonts/Abel-Regular.ttf") format("opentype");
          }

          @font-face {
            font-family: Pacifico;
            src: url("fonts/Pacifico-Regular.ttf") format("opentype");
          }

          .home-background {
            background-image: url("bg-f.jpg");
            position: relative;
            display: block;
            overflow-x: hidden;
            background-size: contain;
            background-attachment: fixed;
          }

          .home-background::before {
            content: "";
            position: absolute;
            background-color: rgba(0, 0, 0, 0.85);
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
          .login-with {
            height: 80vh;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            z-index: 10;
          }

          .white {
            color: #fff;
          }

          .google-login {
            background-color: #ff0000;
            color: #fff;
            padding: 0.5rem 1rem;
            text-decoration: none;
            cursor: pointer;
            font-family: AbelReg;
            transition: opacity 0.5s ease;
            border: 2px solid #000;
            font-size: 0.9rem;
            border-radius: 5px;
          }
          .google-loginr:hover {
            opacity: 0.6;
          }
        `}
      </style>
    </>
  );
}
