// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, doc, setDoc } from "firebase/firestore";
// import { getStorage, ref } from "firebase/storage";
import { GoogleAuthProvider, signInWithPopup, getAuth } from "firebase/auth";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDeweZNvWPahiP6T1xK8AlIA-aoOUFcXa0",
  authDomain: "olimpo-restobar.firebaseapp.com",
  projectId: "olimpo-restobar",
  storageBucket: "olimpo-restobar.appspot.com",
  messagingSenderId: "361568383514",
  appId: "1:361568383514:web:b2c77c74945a69cf5a17a3",
  measurementId: "G-ZMJE9J6FX9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore();

const random = () => Math.random().toString(36).substring(2);
// const olimpoApp = getApp();
// const storage = getStorage(app, "gs://olimpo-restobar.appspot.com");
// const storageRef = ref(storage);

// const analytics = getAnalytics(app);
const auth = getAuth();

//  const querySnapshot = await getDocs(collection(db, "checkout"));
//  querySnapshot.forEach((doc) => {
//      console.log(`${doc.id} => ${doc.data()}`);
//  });


// db.collection("checkout").get().then((querySnapshot) => {
//     querySnapshot.forEach((doc) => {
//         console.log(`${doc.id} => ${doc.data()}`);
//     });
// });

const mapUserFromFirebaseAuthToUser = (user) => {
    const { displayName, email, photoURL, metadata } = user;
   
    return {
        name: displayName,
        email: email,
        photo: photoURL,
        metadata: metadata
    } 
}

export const onAuthStateChanged = (onChange) => {
    return auth.onAuthStateChanged(user => {
    
        const normalizedUser = 
        mapUserFromFirebaseAuthToUser(user)
        onChange(normalizedUser)
    })
}


export const loginWithGoogle = async () => {
    const googleProvider = new GoogleAuthProvider();
    const user = await signInWithPopup(auth, googleProvider);
    return mapUserFromFirebaseAuthToUser(user);
}