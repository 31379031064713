import React, { Suspense, lazy } from "react";
// import Hero from './Hero'
import Login from "./Login";
import Checkout from "./Checkout";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Topbar from "./components/topbar";
import Footer from "./components/footer";
import Loader from "./components/loader";
const Hero = lazy(() => import("./Hero"));
const Menu = lazy(() => import("./Menu"));

export default function App() {
  return (
    <Router>
      <div className="loader-screen kenburns-top"></div>

      <div
        style={{
          position: "absolute",
          zIndex: "11",
          width: "100%",
        }}
      >
        <Topbar />
      </div>

      <Routes>
        <Route
          path="/Menu"
          element={
            <Suspense fallback={<Loader />}>
              <Menu />
            </Suspense>
          }
        />

        <Route path="/Checkout:id" element={<Checkout />} />

        <Route
          path="/"
          element={
            <Suspense fallback={<Loader />}>
              <Hero />
            </Suspense>
          }
        />

        <Route path="/Login" element={<Login />} />
      </Routes>

      <Footer />
      <style jsx='true'>
        {`
          .loader-screen {
            position: fixed;
            height: 100vh;
            background: #000 url(/gif.gif) no-repeat center center;
            background-size: 11%;
            width: 100%;
            z-index: 999;
          }
          .kenburns-top {
            -webkit-animation: loader 4s 0s 1 normal forwards;
            animation: loader 4s 0s 1 normal forwards;
          }

          @-webkit-keyframes loader {
            0%,
            90% {
              display: block;
              opacity: 1;
            }
            100% {
              display: none;
              opacity: 0;
              z-index: 0;
            }
          }
          @keyframes loader {
            0%,
            90% {
              display: block;
              opacity: 1;
            }
            100% {
              display: none;
              opacity: 0;
              z-index: 0;
            }
          }
        `}
      </style>
    </Router>
  );
}
